import React, { useState } from 'react';
import {getMessage} from "../util";
import wsp_icon from "../images/wsp_icon.svg";
import wsp_icon_black from "../images/wsp_icon_black.svg";
import email_icon from "../images/email_icon_black.png";
import telefono_icon from "../images/telefono_icon_black.png";

interface ButtonHelpDeskProps {
    messages: any
}

const ButtonHelpDesk = (props: ButtonHelpDeskProps) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <button
                type="button"
                className="btn floating-btn btn-wsp"
                onClick={handleShow}
            >
                <img className="logo_wsp justify-content-center" src={wsp_icon}/>
                Ayuda
            </button>

            <div className={`modal fade ${show ? 'show d-block' : 'd-none'}`} onClick={handleClose}>
                <div className="modal-dialog" onClick={e => e.stopPropagation()}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Canales de ayuda SRA</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body justify-content-center text-left">
                            <p className="modal-dialog-centered ml-4">
                                <img src={email_icon} className="img-wsp mr-3"/>
                                {getMessage("correo_sra", props.messages)} a&nbsp;
                                <a href="mailto:socios@sra.org.ar">
                                     {getMessage("correo_soporte_sra", props.messages)}
                                </a>
                            </p>
                            <p  className="modal-dialog-centered ml-4">
                                <img src={telefono_icon} className="img-wsp mr-3"/>
                                {getMessage("telefono_sra", props.messages)}&nbsp;
                                <a href="tel:+5491140551845">
                                    {getMessage("telefono_soporte_sra", props.messages)}
                                </a>
                            </p>
                            <p  className="modal-dialog-centered ml-4">
                                <img src={wsp_icon_black} className="img-wsp mr-3" alt="soporte whatsapp"/>
                                Soporte por&nbsp;
                                <a
                                    href="https://wa.me/5491140551845"
                                    role="button"
                                    aria-pressed="true"
                                    target="_blank"
                                >WhatsApp
                                </a>
                            </p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={handleClose}>Cerrar</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ButtonHelpDesk;
