import React, { Fragment} from "react";
import {getMessage} from "./util";

const Encabezado =(props)=> {
    return (
        <Fragment>
            <div className="row justify-content-center">
                <div className="col-12 col-sm-11  text-center m-4 ">
                    <img
                        className="img-fluid logo"
                        alt="Logo"
                        src={props.election.configuration.logo_url}
                    />
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-12 col-sm-12 text-center">
                    <h3 className="font-weight-bold">{getMessage("organization_detail", props.messages)}</h3>
                    <h3 className="font-weight-bold">{props.election.name}</h3>
                    <h3 className="font-weight-bold">{props.election.organization_name}</h3>
                    {props.election.description && <h5>{props.election.description}</h5>}
                </div>
            </div>
        </Fragment>
    )
}

export default Encabezado;