import React, { Fragment} from "react";

const version = require('../package.json');

const Footer =(props)=> {

        return (
            <Fragment>
                <div className="row">
                    <div className="col-12 text-right">
                        <small>© EVoting {new Date().getFullYear()}, v. {version.version}</small>
                    </div>
                </div>
            </Fragment>

        );

}


export default Footer;