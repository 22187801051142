import React, { Fragment} from "react";
import './Election.css';
import Encabezado from "./Encabezado";
import ComoVotar from "./ComoVotar";
import ButtonElection from "./ButtonElection";
import FooterNew from "./FooterNew";
import ButtonHelpDesk from "./components/ButtonHelpDesk";
import Video from "./Video";


const Election =(props)=> {
    const linkVideo = props.election.configuration.video_url;

    return (
        <Fragment>
            <ButtonHelpDesk/>
            <Encabezado messages={props.election.messages} election={props.election}/>
            <ButtonElection messages={props.election.messages} election={props.election}/>
            <FooterNew messages={props.election.messages} election={props.election}/>
            <ComoVotar messages={props.election.messages} election={props.election} />
            {linkVideo === "" ? null : <Video video_url={props.election.configuration.video_url} />}
        </Fragment>
    );
}


export default Election;